import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./special-route.scss";

import CourseCard from "../course-card/course-card";
import SpecialCourses from "./special-courses";

const SpecialRoute = () => {
    const { t } = useTranslation();
    const [data, setData] = useState(SpecialCourses);

    return (
        <div className="special-route">
            <div className="online_el_inner">
                <div className="online_el_header">
                    <div className="online_el_header_title">
                        Tech Orda арнайы бағыт 3.3
                    </div>
                    <div className="online_el_header_title">
                        Специализированный поток 3.3
                    </div>
                </div>

                <div className="online_el_subheader">
                    Жоғары оқу орындарының оқытушыларының біліктілігін көтеруге арналған Tech Orda бағдарламасының арнайы бағыты 3.3 бойынша даярлау курстары. Курстар онлайн форматта, қазақ / орыс тілдерінде ұйымдастырылады.
                </div>
                <div className="course_cards_container">
                    {data.map((item) => {
                        const { id } = item;
                        return <CourseCard key={id} {...item} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default SpecialRoute;
