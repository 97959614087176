import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationRU from "./locales/ru/translationRU.json";
import translationKZ from "./locales/kz/translationKZ.json";

const resources = {
  ru: {
    translation: translationRU,
  },
  kz: {
    translation: translationKZ,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "kz",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
