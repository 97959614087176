import "./button-component.scss";

const ButtonComponent = ({ isWhite, text }) => {
  let classNames = "btn";

  if (isWhite) {
    classNames += " white";
  }

  return <button className={classNames}>{text}</button>;
};

export default ButtonComponent;
