import { useParams } from "react-router-dom";
import Courses from "../all-courses/courses";

import "./card-detail-page.scss";
import LogoInDetailPage from "../../images/logo-3-removebg-preview.png";

const CardDetailPage = () => {
  const { productId } = useParams();
  //   const thisProduct = Courses.find((prod) => prod.id === productId);

  const { name, description, time, color, price } = Courses[productId];
  const backgroundColor = color;

  console.log(Courses[productId]);

  return (
    <div className="card_detail_page">
      <div className="card_detail_page_inner">
        <a className="card_detail_page_header" href="/">
          <img
            src={LogoInDetailPage}
            alt=""
            className="card_detail_page_header_logo"
          />
        </a>
        <div
          className="card_detail_page_main"
          style={{ backgroundColor: backgroundColor }}
        >
          <div className="card_detail_page_main_title">{name}</div>
          <div className="card_detail_page_main_description">{description}</div>
        </div>
        <div className="card_detail_page_info">
          <div className="card_detail_page_info_time">{time}</div>
          <div className="card_detail_page_info_price">{price}</div>
        </div>
      </div>
    </div>
  );
};

export default CardDetailPage;
