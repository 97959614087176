import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

import "./home.scss";

import ButtonComponent from "../common/button-component/button-component";
import Frame1 from "../../images/Frame_home_1.svg";
import Frame2 from "../../images/Frame_home_2.svg";
import Frame3 from "../../images/Frame_home_3.svg";
import SmallCircle from "../../images/CircleHome.svg";
import ArrowsGroupHome from "../../images/vectorgrouphome.svg";
import VerticalArrowHome from "../../images/VectorVerticalHome.svg";
import CircleArrowHome from "../../images/VectorCircleHome.svg";
import RectangleHome from "../../images/RectangleHome.png";
import MenImgHome from "../../images/MenImgHome.png";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="home">
      {/* <img src={HomeImg} alt="" /> */}
      <div className="home_inner">
        <div className="left_side_home">
          <div className="title_text_home">
            <div className="first_line_title_text">
              {/* Вкладывай */}
              {t("firstLineTextInHomeBlock")}
              <div>
                <img src={Frame1} alt="" />
              </div>
            </div>
            <div className="second_line_title_text">
              <div className="circle_home">
                <img src={Frame3} alt="" />
                <img
                  className="vertical_arrow"
                  src={VerticalArrowHome}
                  alt=""
                />
                <img
                  className="vertical_arrow2"
                  src={VerticalArrowHome}
                  alt=""
                />
                <img
                  className="vertical_arrow3"
                  src={VerticalArrowHome}
                  alt=""
                />
                <img
                  className="vertical_arrow4"
                  src={VerticalArrowHome}
                  alt=""
                />
                <img className="circle_arrow" src={CircleArrowHome} alt="" />
                <img className="small_circle" src={SmallCircle} alt="" />
              </div>
              <div>
                {/* в своё */}
                {t("secondLineTextInHomeBlock")}
              </div>
              <div>
                <img src={Frame2} alt="" />
              </div>
            </div>
            <div className="third_line_title_text">
              <img src={ArrowsGroupHome} alt="" />
              {/* будущее */}
              {t("thirdLineTextInHomeBlock")}
            </div>
          </div>
          <div className="two_buttons">
            <Link
              to="all_courses"
              spy={true}
              smooth={true}
              offset={-100}
              duration={1000}
            >
              <ButtonComponent
                isWhite={false}
                text={t("blueButton2TextInHomeBlock")}
              />
            </Link>

            <Link
              to="info-cta"
              spy={true}
              smooth={true}
              offset={500}
              duration={1000}
            >
              <ButtonComponent
                isWhite={true}
                text={t("whiteButtonTextInHomeBlock")}
              />
            </Link>
            <Link
              to="course_requirements"
              spy={true}
              smooth={true}
              offset={-100}
              duration={1000}
            >
              <ButtonComponent isWhite={true} text={"Курсқа түсу"} />
            </Link>
          </div>
        </div>

        <div className="image_home">
          <img src={RectangleHome} alt="" />
        </div>
        <img className="men_img" src={MenImgHome} alt="" />
      </div>
    </div>
  );
};

export default Home;
