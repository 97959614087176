import React from "react";
import ButtonComponent from "../common/button-component/button-component";
import Logo from "../../images/logo-3-removebg-preview.png";
import { Link } from "react-scroll";

import "./header.scss";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

// eslint-disable-next-line no-unused-vars
const changeLanguage = (ln) => {
  return () => {
    i18n.changeLanguage(ln);
  };
};

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="header">
      <div className="inner_header">
        <div className="logo_container">
          <img src={Logo} alt="KazMBS_Logo" />
        </div>

        <div className="nav_btn_container">
          <div className="ln_btn">
            {/* <button className="ln_btn_kz" onClick={changeLanguage("kz")}>
              KZ
            </button> */}
            {/* <button className="ln_btn_ru" onClick={changeLanguage("ru")}>
              RU
            </button> */}
          </div>
          <div className="header_buttons">
            <Link
              to="footer"
              spy={true}
              smooth={true}
              offset={-100}
              duration={1000}
            >
              <ButtonComponent
                isWhite={true}
                text={t("blueButtonTextInHomeBlock")}
              />
            </Link>
            <ButtonComponent isWhite={false} text={"Кіру"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
