import React from "react";
import "./reviews.scss";

import ArrowLeft from "../../images/ArrowLeft2.svg";
import ArrowRight from "../../images/ArrowRight2.svg";
import StudentImg from "../../images/experts_img1.svg";
import RatingIcon from "../../images/rating_ic.svg";

import Bake from "../../images/Bake.jpeg";
import Make from "../../images/Make.jpeg";
import Apai from "../../images/Apai.jpeg";

const Reviews = () => {
  return (
    <div className="reviews">
      <div className="review_inner">
        <div className="reviews_header">
          <div className="reviews_header_title">
            Курстардан өткен студенттердің <span>пікірлері</span>
          </div>
          <div className="review_header_arrows">
            <button className="arrow_left">
              <img src={ArrowLeft} alt="" />
            </button>
            <button className="arrow_right">
              <img src={ArrowRight} alt="" />
            </button>
          </div>
        </div>
        <div className="reviews_cards_container_outside">
          <div className="reviews_cards_container">
            <div className="review_background_gradient"></div>
            <div className="review_card">
              <div className="review_card_student">
                <img src={Make} alt="" className="expert_img" />
                <div>
                  <div className="name">Уксикбаев Максат</div>
                  <div className="subtitle">
                    ”Android, iOS программалау” курсы туралы
                  </div>
                </div>
              </div>
              <div className="review_card_text">
                Пайдалы курс, көптеген материалдар, көптеген қызықты ақпарат.
              </div>
              <div className="review_rating">
                <img src={RatingIcon} alt="" />
                <div className="rating">
                  5 <span>/5</span>
                </div>
              </div>
            </div>
            {/* <div className="review_card">
              <div className="review_card_student">
                <img src={Bake} alt="" className="expert_img" />
                <div>
                  <div className="name">Нурымбетов Берик</div>
                  <div className="subtitle">
                    “Java программалау” курсы туралы
                  </div>
                </div>
              </div>
              <div className="review_card_text">
                Тамаша курс. Қызықты тапсырмалар және өте көп ақпарат.
                Мұғалімдер өте білікті. Өз пәнін жақсы білу, оптимизм және
                шексіз энергия. Барлық дәрістер бір демде өтті.
              </div>
              <div className="review_rating">
                <img src={RatingIcon} alt="" />
                <div className="rating">
                  5 <span>/5</span>
                </div>
              </div>
            </div> */}
            <div className="review_card">
              <div className="review_card_student">
                <img src={Apai} alt="" className="expert_img" />
                <div>
                  <div className="name">Абдрашова Эльмира</div>
                  <div className="subtitle">
                    "Web программалау” курсы туралы
                  </div>
                </div>
              </div>
              <div className="review_card_text">
                Пайдалы курс, көптеген материалдар, көптеген қызықты ақпарат.
              </div>
              <div className="review_rating">
                <img src={RatingIcon} alt="" />
                <div className="rating">
                  5 <span>/5</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
