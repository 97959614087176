import React from "react";
import "./video.scss";

function Video2() {
  return (
    <div className="container_video">
      <div className="video">
        <iframe
          id="player"
          className="video_frame"
          src="https://www.youtube.com/embed/j-f6tGPUgdEs?mute=1&enablejsapi=1&rel=0&color=white&disablekb=1&playlist=j-f6tGPUgdE&showinfo=0"
          // width="560"
          // height="315"
          title="ALAQAN Presentation"
          frameBorder="0"
          allow="autoplay; fullscreen"
        ></iframe>
      </div>
    </div>
  );
}

export default Video2;
