import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import PDF0 from "../../syllabus/java_syllabus.pdf";
import PDF1 from "../../syllabus/2- программалау тілі.pdf";
import PDF2 from "../../syllabus/3-Веб-қосымшалар даярлау-frotend.pdf";
import PDF3 from "../../syllabus/4-Веб-қосымшалар даярлау-backend.pdf";
import PDF4 from "../../syllabus/5-Мобильді қосымшалар даярлау.pdf";
import PDF5 from "../../syllabus/6-Syllabus-Цифрлықдизайнианимация.pdf";
import PDF8 from "../../syllabus/8-KazMBS_Syllabus_жасанды интеллект базалык_каз.pdf";
import PDF9 from "../../syllabus/9-KazMBS_Syllabus_жасанды интеллект озык_каз.pdf";
import PDF10 from "../../syllabus/10-KazMBS_Syllabus_искусственный интеллект базовый_рус.pdf";
import PDF11 from "../../syllabus/11-KazMBS_Syllabus_искусственный интеллект продвинутый_рус.pdf";
import "./course-card.scss";

import ArrowRight from "../../images/ArrowRight1.svg";
import CardDetailPage from "../card-detail-page/card-detail-page";

const CourseCard = (props) => {
  // const [products, setProducts] = useState([]);
  const backgroundColor = props.color;
  var pdfPath;
  if (props?.id === 0) {
    pdfPath = PDF0;
  } else if (props?.id === 1) {
    pdfPath = PDF1;
  } else if (props?.id === 2) {
    pdfPath = PDF2;
  } else if (props?.id === 3) {
    pdfPath = PDF3;
  } else if (props?.id === 4) {
    pdfPath = PDF4;
  } else if (props?.id === 7) {
    pdfPath = PDF5;
  } else if (props?.id === 8) {
    pdfPath = PDF8;
  } else if (props?.id === 9) {
    pdfPath = PDF9;
  } else if (props?.id === 10) {
    pdfPath = PDF10;
  } else if (props?.id === 11) {
    pdfPath = PDF11;
  }

  return (
    <div
      className="course_card"
      key={props.id}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="course_card_header">
        <div className="course_duration">{props.time}</div>
        <div className="course_starting_date">{props.startTime}</div>

        {/* <Link className="icon_button" to={`/products/${props.id}`}> */}
        <div className="icon_button">
          <img src={ArrowRight} alt="" />
        </div>
        {/* </Link> */}
      </div>
      <div className="course_card_description">
        <div className="course_card_description_title">{props.name}</div>
        <div className="course_card_description_text">{props.description}</div>
      </div>
      <div className="course_card_footer">
        <div className="course_card_description_price">{props.price}</div>
        <a
          className="course_card_footer_dwl_btn"
          href={pdfPath}
          download="Syllabus"
        >
          Силлабусты жүктеу
        </a>
      </div>
      <Routes>
        <Route path="/products" element={<CardDetailPage {...props} />} />
      </Routes>
    </div >
  );
};

export default CourseCard;
