import React from "react";
import { useTranslation } from "react-i18next";
import "./online-el.scss";

import ArrowLeft from "../../images/ArrowLeft.svg";
import ArrowRight from "../../images/ArrowRight.svg";

const OnlineEL = () => {
  const { t } = useTranslation();
  return (
    <div className="online_el">
      <div className="online_el_inner">
        <div className="online_el_header">
          <span className="online_el_header_title">
            {t("onlineFormatTextInOnlineEL")}
          </span>
          <div className="online_el_header_arrows">
            <img src={ArrowLeft} alt="" />

            <img src={ArrowRight} alt="" />
          </div>
        </div>
        <div className="online_el_cards_container_outside">
          <div className="online_el_cards_container">
            <div className="background_gradient"></div>
            <div
              className="online_el_card"
              style={{ backgroundColor: "#D8E7AB" }}
            >
              <div className="card_number">
                <span>1</span>
              </div>
              <div className="card_name">{t("firstCardTextInOnlineEL")}</div>
            </div>

            <div
              className="online_el_card"
              style={{ backgroundColor: "#D9BAE3" }}
            >
              <div className="card_number">
                <span>2</span>
              </div>
              <div className="card_name"> {t("secondCardTextInOnlineEL")}</div>
            </div>

            <div
              className="online_el_card"
              style={{ backgroundColor: "#E0C3B5" }}
            >
              <div className="card_number">
                <span>3</span>
              </div>
              <div className="card_name">{t("thirdCardTextInOnlineEL")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineEL;
