import React from "react";

import "./courseRequirements.scss";

const CourseRequirements = () => {
  return (
    <div className="course_requirements" id="course_requirements">
      <div className="course_requirements_title">
        Курсқа қабылдану үшін талапкерге қойылатын критерийлер:
      </div>
      <div className="course_requirements_body">
        * талапкердің жасы 18-45 жас аралығында болуы;
      </div>
      <div className="course_requirements_body">
        * ақпараттық технологиялар пәнінен сауаттылық деңгейі;
      </div>
      <div className="course_requirements_body">
        * математика пәнінен сауаттылық деңгейі.
      </div>

      <div className="course_requirements_footer">
        Бұл критерийлер курста оқу үшін қажетті білім деңгейін анықтайды, яғни
        талапкер курсқа қабылдану үшін бірінші кезекте мектеп комиссиясымен
        сұхбаттасудан өтеді және ақпараттық технологиялар, математика пәндерінен
        сауаттылық деңгейін анықтайтын тест тапсырады. Курсқа әр пәннен 20
        тапсырмадан тұратын тест жүйесінен 50% тапсырмаларды дұрыс орындай алған
        талапкерлер ғана қабылданады.
      </div>

      <div className="course_requirements_title">
        Мақсатты аудитория:
      </div>
      <div className="course_requirements_body">
        * IT саласында жаңа білім аламын деушілер;
      </div>
      <div className="course_requirements_body">
        * біліктілік арттырамын деуші IT мамандар;
      </div>
      <div className="course_requirements_body">
        * біліктілік көтеретін педагог-мамандар.
      </div>
    </div>
  );
};

export default CourseRequirements;
