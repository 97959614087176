import React from "react";
import "antd/dist/antd.min.css";
import { Routes, Route } from "react-router-dom";
import { Carousel } from "antd";
import "./app.css";

import Header from "../header/header";
import Home from "../home/home";
import InfoCTA from "../info-cta/info-cta";
import OnlineEL from "../online-el/online-el";
import AllCourses from "../all-courses/all-courses";
import Reviews from "../reviews/reviews";
import Experts from "../experts/experts";
import Footer from "../footer/footer";
import Video from "../video/Video";
import Video2 from "../video/Video2";
import CardDetailPage from "../card-detail-page/card-detail-page";
import CourseRequirements from "../course-requirements/CourseRequirements";
import SpecialRoute from "../special-route/special-route";

import img1 from "../../images/img1.jpeg";
import img2 from "../../images/img2.jpeg";
import img3 from "../../images/img3.jpeg";
import img4 from "../../images/img4.jpeg";
import img5 from "../../images/img5.jpeg";
import img6 from "../../images/img6.jpeg";

const App = () => {
  return (
    <Routes>
      <Route path="*" element={<AllPages />} />
      <Route path="/products/:productId" element={<CardDetailPage />} />
    </Routes>
  );
};

const AllPages = () => {
  const contentStyle = {
    height: "90vh",
    width: "auto",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
    margin: "0 auto",
  };
  const carouselStyle = {
    // dispaly: "flex",
    // flexDirection: "coloumn",
  };
  return (
    <div className="app">
      <Header />
      <Home />
      <Video />
      <InfoCTA />
      <SpecialRoute />
      <AllCourses />
      {/* <Video2 /> */}
      <OnlineEL />
      <Experts />
      <CourseRequirements />
      <Reviews />
      <div className="carousel">
        <Carousel style={carouselStyle} autoplay={true}>
          <div>
            <img src={img1} alt="" style={contentStyle} />
          </div>
          <div>
            <img src={img2} alt="" style={contentStyle} />
          </div>
          <div>
            <img src={img3} alt="" style={contentStyle} />
          </div>
          <div>
            <img src={img4} alt="" style={contentStyle} />
          </div>
          <div>
            <img src={img5} alt="" style={contentStyle} />
          </div>
          <div>
            <img src={img6} alt="" style={contentStyle} />
          </div>
        </Carousel>
      </div>
      <Footer />
    </div>
  );
};

export default App;
