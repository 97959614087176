import React from "react";
import "./footer.scss";

import FooterLogo from "../../images/logo-3-removebg-preview.png";
import Insta from "../../images/instagram.jpeg";

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="footer_inner">
        <div className="footer_container">
          <div className="footer_logo">
            <img src={FooterLogo} alt="" />
            <div className="contact_container">
              <div className="contact_text">Байланыс</div>
              <div className="number_text">+7 (707) 979 86 48</div>
              <div className="work_grahic_text">
                Жұмыс уақыты: 10:00-19:00 (ДС-СБ)
              </div>
              <div className="email_text">irckz@mail.ru</div>
              <div className="footer_insta">
                <img src={Insta} alt="" />
                <a
                  className="addres_text"
                  href="https://instagram.com/it_school_kazmbs?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                >
                  https://instagram.com/it_school_kazmbs?igshid=YmMyMTA2M2Y=
                </a>
              </div>
              <a
                className="addres_text"
                href="https://2gis.kz/shymkent/search/%D1%83%D0%BB%D0%B8%D1%86%D0%B0%20%D0%91%D0%B0%D0%B9%D1%82%D1%83%D1%80%D1%81%D1%8B%D0%BD%D0%BE%D0%B2%D0%B0%2C%C2%A080%20%D0%BA%D0%BE%D1%80%D0%BF%D1%83%D1%81%20%D0%90/firm/70000001061094708?m=69.604463%2C42.338798%2F18"
                target="_blank"
                rel="noreferrer"
              >
                2gis: қ. Шымкент, Байтурсынов көшесі, 80, корпус А,
              </a>
            </div>
          </div>

          <div className="footer_text">Барлық құқықтар қорғалған. 2022 ж.</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
