const SpecialCourses = [
    {
        id: 8,
        name: "Жасанды интеллект: Базалық курс",
        description:
            "Цифрландыру және технологиялардың қарқынды дамуы дәуірінде бағдарламалау және жасанды интеллект (AI) негіздерін білу тек құнды активке ғана емес, сонымен қатар әр түрлі саладағы мамандар үшін қажетті құралға айналды. Python өзінің қол жетімділігі мен қуатты мүмкіндіктерінің арқасында жасанды интеллект жүйелерін дамытуда басты орын алатын жетекші бағдарламалау тілдерінің бірі ретінде ерекшеленеді. Сондықтан «Жасанды интеллект» бағдарламалау негіздерін үйретуге және қуатты Python құралдарымен қамтамасыз етілген жасанды интелекттің негізгі тұжырымдамаларына кіріспе жасауға бағытталған.",
        time: "6 ай",
        startTime: "1-қазаннан",
        category: "web",
        color: "#53A8D8",
        price: "600 000тг",
    },
    {
        id: 10,
        name: "Искусственный интеллект: Базовый курс",
        description:
            "В эпоху цифровизации и стремительного развития технологий знание основ программирования и искусственного интеллекта (ИИ) становится не просто ценным активом, а необходимым инструментом для специалистов самых разных сфер. Python, благодаря своей доступности и мощным возможностям, выделяется как один из ведущих языков программирования, занимая центральное место в разработке ИИ-систем. Именно поэтому курс «Искусственный интеллект» нацелен на обучение основам программирования и введение в ключевые концепции ИИ, подкрепленные мощным инструментарием Python",
        time: "6 месяцев",
        startTime: "С 1 октября",
        category: "web",
        color: "rgb(216, 231, 171)",
        price: "600 000 тг",
    },

    {
        id: 9,
        name: "Жасанды интеллект: Жетілдірілген курс",
        description:
            "«Жасанды интеллект: Жетілдірілген курс» Python бағдарламалау және машиналық оқыту негіздері бойынша негізгі білімі бар студенттер мен мамандарға арналған, олар жасанды интеллект (ЖИ) пен терең оқытудың озық технологияларында өз дағдыларын тереңдетуге тырысады. Курс бағдарламасы нақты жобаларда білімді практикалық қолдануға баса назар аудара отырып, Natural Language Processing (NLP), терең нейрондық желілер және генеративті модельдер саласындағы заманауи тәсілдерді игеруге бағытталған. Курс аясында студенттер қайталанатын нейрондық желілер(RNN), Long Short- Term Memory(LSTM), трансформаторлар және заманауи конволюциялық нейрондық желі архитектуралары(CNN) сияқты негізгі әдістер мен тәсілдерді үйренеді.Курс сонымен қатар гиперпараметрлерді оңтайландыру, AutoML пайдалану және шағын деректер мәселелерін шешу үшін Transfer Learning қолдану сияқты маңызды аспектілерді қамтиды.",
        time: "6 ай",
        startTime: "С 1 октября",
        category: "web",
        color: "rgb(150, 132, 254)",
        price: "600 000 тг",
    },

    {
        id: 11,
        name: "Искусственный интеллект: Продвинутый курс",
        description:
            "Курс Искусственный интеллект: Продвинутый курс разработан для студентов и специалистов, обладающих базовыми знаниями в области программирования на Python и основами машинного обучения, которые стремятся углубить свои навыки в передовых технологиях искусственного интеллекта и глубокого обучения. Программа курса ориентирована на освоение современных подходов в области Natural Language Processing (NLP), глубинных нейронных сетей и генеративных моделей, с акцентом на практическое применение знаний в реальных проектах. В рамках курса студенты изучат ключевые методы и подходы, такие как рекуррентные нейронные сети (RNN), Long Short-Term Memory (LSTM), трансформеры и современные архитектуры сверточных нейронных сетей (CNN). Курс также включает важные аспекты, такие как оптимизация гиперпараметров, использование AutoML и применение Transfer Learning для решения задач с небольшими данными.",
        time: "6 месяцев",
        startTime: "С 1 октября",
        category: "web",
        color: "rgb(254, 200, 70)",
        price: "600 000 тг",
    },
];

export default SpecialCourses;
