const Courses = [
  {
    id: 2,
    name: "Веб-қосымшаларды даярлау - frontend",
    description:
      "Frontend әзірлеушілерді шығаратын “Веб программалау” курсында білім алушылар веб-сайттар, веб-қосымша интерфейсін жобалау, деректер базасымен жұмыс жасау технологияларын  оқып үйренеді. HTML, CSS, JavaScript, ReactJS",
    time: "6 ай",
    startTime: "1-қыркүйектен",
    category: "web",
    color: "#FEC846",
    price: "600 000тг",
  },
  {
    id: 3,
    name: "Веб-қосымшаларды даярлау - backend",
    description:
      "Backend әзірлеушілерді шығаратын “Веб программалау” курсында білім алушылар веб-сайттар, веб-қосымша интерфейсін жобалау, деректер базасымен жұмыс жасау технологияларын  оқып үйренеді. PHP Laravel",
    time: "6 ай",
    startTime: "1-қыркүйектен",
    category: "web",
    color: "#D9BAE3",
    price: "600 000 тг",
  },
  {
    id: 0,
    name: "Java программалау тілі",
    description:
      "Курс программалауды жаңадан үйренетін білім алушыларға қазіргі кезде ақпараттық технологияларда кеңінен қолданылатын Java программалау тілдерінің негіздерін оқытуға арналған.",
    time: "6 ай",
    startTime: "1-қыркүйектен",
    category: "basic",
    color: "#D8E7AB",
    price: "600 000тг",
  },
  {
    id: 1,
    name: "Python жане жасанды интеллект",
    description:
      "Курс Python бағдарламалау тілін, жасанды интеллект (AI) және машиналық оқыту (ML) негіздерін терең түсіндіру арқылы, алынған білімді нақты мәселелерді шешуде қолдану дағдылары мен құзыреттіліктерін қалыптастырады.",
    time: "6 ай",
    startTime: "1-қыркүйектен",
    category: "ai",
    color: "#53A8D8",
    price: "600 000тг",
  },
  {
    id: 4,
    name: "Мобильді қосымшаларды даярлау",
    description:
      "Android және iOS платформаларында мобилді қосымшаларды даярлау - ұялы телефондарға қарапайым ойындар жасаудан бастап, замануи технологияларды қолданатын кәсіби жүйелерге дейін кең ауқымдағы программалық платформаларды даярлауға көмегін тигізеді.",
    time: "6 ай",
    startTime: "1-қыркүйектен",
    category: "mobile",
    color: "#FFFF66",
    price: "600 000тг",
  },
  // {
  //   id: 5,
  //   name: "Интернет заттары (IoT)",
  //   description:
  //     "Интернет заттары (IoT)",
  //   time: "6 ай",
  //   startTime: "15 маусымнан",
  //   category: "iot",
  //   color: "#D6F379",
  //   price: "600 000тг",
  // },
  // {
  //   id: 6,
  //   name: "Жасанды интеллект (AI)",
  //   description:
  //     "Жасанды интеллект (AI)",
  //   time: "6 ай",
  //   startTime: "15 маусымнан",
  //   category: "ai",
  //   color: "#12C2E9",
  //   price: "600 000тг",
  // },
  {
    id: 7,
    name: "Цифрлық дизайн және анимация",
    description:
      "Курс CorelDraw, Adobe Photoshop, Adobe After Effects және Blender сияқты жетекші графикалық бағдарламалық құралдармен жұмыс жасау негіздерімен таныстырады.",
    time: "6 ай",
    startTime: "1-қыркүйектен",
    category: "design",
    color: "#F64F59",
    price: "600 000тг",
  },
];

export default Courses;
