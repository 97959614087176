import React from "react";
import "./video.scss";

function Video() {
  return (
    <div className="container_video">
      <div className="video">
        <iframe
          id="player"
          className="video_frame"
          src="https://www.youtube.com/embed/jp4n8qlMMDo?autoplay=1&mute=1&enablejsapi=1&rel=0&color=white&disablekb=1&playlist=jp4n8qlMMDo&showinfo=0"
          title="ALAQAN Presentation"
          frameBorder="0"
          allow="autoplay; fullscreen"
        ></iframe>
      </div>
      {/* <div className="video">
        <iframe
          id="player"
          className="video_frame"
          src="https://www.youtube.com/embed/gVgO3gWN0JE?autoplay=1&mute=1&enablejsapi=1&rel=0&color=white&disablekb=1&playlist=gVgO3gWN0JE&showinfo=0"
          // width="560"
          // height="315"
          title="ALAQAN Presentation"
          frameBorder="0"
          allow="autoplay; fullscreen"
        ></iframe>
      </div> */}
    </div>
  );
}

export default Video;
