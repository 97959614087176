import { React, useRef } from "react";
import "./experts.scss";

import ArrowLeft from "../../images/ArrowLeft2.svg";
import ArrowRight from "../../images/ArrowRight2.svg";
import Kaneke from "../../images/Kaneke.jpeg";
import Ulan from "../../images/Ulan.jpeg";
import Men from "../../images/Men.jpeg";
// import Zhake from "../../images/Zhake.jpeg";
import Khaseke from "../../images/Khaseke.jpeg";
import Nureke from "../../images/Nureke.jpeg";
import Bolat from "../../images/Bolat.jpeg";
import Saken from "../../images/Saken.jpeg";
import Nagima from "../../images/Nagima.jpeg";

const Experts = () => {
  const ref = useRef(null);

  const scroll = (direction) => {
    // Scrolling the container based on direction. Positive for right, negative for left.
    const scrollAmount = direction === 'left' ? -100 : 100;
    ref.current.scrollLeft += scrollAmount;
  };

  return (
    <div className="experts">
      <div className="experts_inner">
        <div className="experts_header">
          <div className="experts_header_title">Біздің оқытушылар</div>
          <div className="experts_header_arrows">
            <button className="experts_arrow_left">
              <img src={ArrowLeft} alt="" onClick={() => scroll('left')} />
            </button>
            <button className="experts_arrow_right" onClick={() => scroll('right')}>
              <img src={ArrowRight} alt="" />
            </button>
          </div>
        </div>

        <div className="experts_cards_container_outside">
          <div ref={ref} className="experts_cards_container">
            <div className="experts_background_gradient"></div>
            <div className="expert_card">
              <img src={Saken} alt="" className="expert_img" />
              <div className="expert_name">
                Длимбетов <div>Сәкен</div>
                <div>Серікұлы</div>
              </div>
              <div className="expert_pro">
                Мектеп директоры
                <div>+7 777 297 7700</div>
              </div>
            </div>
            <div className="expert_card">
              <img src={Khaseke} alt="" className="expert_img" />
              <div className="expert_name">
                Жантелі <div>Хасен</div>
                <div>Асқарұлы</div>
              </div>
              <div className="expert_pro">Директор орынбасары,
                <div>
                  C# программалау тілі
                </div>
              </div>
            </div>
            <div className="expert_card">
              <img src={Kaneke} alt="" className="expert_img" />
              <div className="expert_name">
                Сәрсенбаев <div>Қанат</div> <div>Әділханұлы</div>
              </div>
              <div className="expert_pro">Python және жасанды
                <div>интеллект</div>
                <div>+7 705 995 6008</div>
              </div>
            </div>
            <div className="expert_card">
              <img src={Nureke} alt="" className="expert_img" />
              <div className="expert_name">
                Дуйсенов <div>Нұрзак</div>
                <div>Жезтаевич</div>
              </div>
              <div className="expert_pro">
                Java <div>программалау тілі</div>
              </div>
            </div>
            {/* <div className="expert_card">
              <img src={Zhake} alt="" className="expert_img" />
              <div className="expert_name">
                Жунисов <div>Жандос</div>
                <div>Мухидинович</div>
              </div>
              <div className="expert_pro">Мобильді <div>қосымшаларды әзірлеу</div></div>
            </div> */}
            <div className="expert_card">
              <img src={Men} alt="" className="expert_img" />
              <div className="expert_name">
                Бекнияз <div>Әлібек</div>
                <div>Азаматұлы</div>
              </div>
              <div className="expert_pro">
                Веб бағдарламалау <span>(Frontend)</span>
              </div>
            </div>
            <div className="expert_card">
              <img src={Ulan} alt="" className="expert_img" />
              <div className="expert_name">
                Абихан <div>Ұлан</div>
                <div>Манапұлы</div>
              </div>
              <div className="expert_pro">
                Android бағдарламалау
                <span>және Backend</span>
              </div>
            </div>
            <div className="expert_card">
              <img src={Nagima} alt="" className="expert_img" />
              <div className="expert_name">
                Шойғараева <div>Нағима</div>
                <div>Оразымбетқызы</div>
              </div>
              <div className="expert_pro">
                Цифрлық дизайн
                <div>
                  және анимация
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experts;
