import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import validator from "validator";
import "./info-cta.scss";

import BackgroundImage from "../../images/162.png";
// import ButtonComponent from "../common/button-component/button-component";
import WomanImg from "../../images/info_cta_image.png";
import Icon from "../../images/icon_done.svg";

const InfoCTA = () => {
  const [data, setData] = useState({
    phone: "",
  });

  function submit(e) {
    e.preventDefault();
    const url = "https://lhhta28de6.execute-api.eu-central-1.amazonaws.com/v0";

    if (data.phone !== "") {
      const answer = window.confirm("Подтвердить отправку заявки");
      if (answer) {
        axios.post(url, {
          site: "kazmbs.kz",
          text: `<b>Новая заявка</b>\n<b>Номер телефона:</b> ${data.phone}`,
        });

        setData({
          phone: "",
        });
      }
    } else {
      window.alert("Неправильный формат номера!!!");
    }
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  // function isEmpty() {
  //   if (data.phone !== "" && validator.isMobilePhone(data.phone)) {
  //     document.getElementById("submit_btn").removeAttribute("disabled");
  //   }
  // }

  const { t } = useTranslation();
  return (
    <div className="info_cta_content" id="info-cta">
      <div className="info_cta_content_inner">
        <div className="info_cta_header">
          <div className="lessons first">
            <div className="lessons_count">72</div>
            <div className="lesson_text">сабақ</div>
          </div>
          <div className="lessons second">
            <div className="lessons_count">2303</div>
            <div className="lesson_text">қолданушы</div>
          </div>
          <div className="lessons third">
            <div className="lessons_count">7</div>
            <div className="lesson_text">тәлімгер</div>
          </div>
          <div className="vl fourth"></div>
          <div className="lessons">
            <div className="lessons_count">75% студент</div>
            <div className="lesson_text fifth">
              Курсты аяқтағаннан кейін жұмыспен қамтылады
            </div>
          </div>
        </div>

        <div className="info_cta_footer">
          <div className="info_cta_footer_left">
            <img src={WomanImg} alt="" />
          </div>
          <div className="info_cta_footer_right">
            <div className="info_cta_footer_right_title">
              {t("komuPodoidetTextInInfoCtaBlock")}
            </div>
            <div className="info_cta_footer_right_subtitles">
              <img src={Icon} alt="" />
              <span>{t("firstSubtitleTextInInfoCtaBlock")}</span>
            </div>
            <div className="info_cta_footer_right_subtitles">
              <img src={Icon} alt="" />
              <span> {t("secondSubtitleTextInInfoCtaBlock")}</span>
            </div>
            <div className="info_cta_footer_right_subtitles">
              <img src={Icon} alt="" />
              <span> {t("thirdSubtitleTextInInfoCtaBlock")}</span>
            </div>
          </div>
        </div>

        <div className="info_cta_body">
          <div className="body_container_left">
            <div className="body_container_left_title">
              {t("pomozhemTextInInfoCtaBlock")}
            </div>
            <div className="body_container_left_form">
              <form onSubmit={(e) => submit(e)}>
                <input
                  onChange={(e) => handle(e)}
                  type="text"
                  placeholder={t("placeHolderTextInInfoCtaBlock")}
                  id="phone"
                  value={data.phone}
                  name="phone"
                // onKeyUp={isEmpty()}
                />
                <button className="submit_btn_info_cta" id="submit_btn">
                  {t("buttonTextInInfoCtaBlock")}
                </button>
              </form>
            </div>
            <div className="body_container_left_subtitle">
              {t("underTextFiledTextInInfoCtaBlock")}
            </div>
          </div>
          <div className="body_container_right">
            <img src={BackgroundImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCTA;
