import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./all-courses.scss";
import Courses from "./courses";

import CourseCard from "../course-card/course-card";

const AllCourses = () => {
  const { t } = useTranslation();
  const [appState, changeState] = useState({
    activeObject: null,
    objects: [
      // { category: "all", label: "Барлығы", index: 0 },
      { category: "basic", label: "Программалау тілдері", index: 0 },
      { category: "web", label: "Web қосымшалар даярлау", index: 1 },
      { category: "mobile", label: "Мобильді қосымшалар даярлау", index: 2 },
      // { category: "iot", label: "IoT", index: 4 },
      { category: "ai", label: "Python және жасанды интеллект", index: 3 },
      { category: "design", label: "Дизайн", index: 4 },
    ],
  });
  const [data, setData] = useState(Courses);

  function toggleActive(index) {
    changeState({ ...appState, activeObject: appState.objects[index] });
  }

  function toggleActiveStyles(index) {
    if (appState.objects[index] === appState.activeObject) {
      return "course_name active";
    } else {
      return "course_name";
    }
  }

  const filterResult = (catItem) => {
    console.log(catItem);

    let result;

    if (catItem !== 'all') {
      result = Courses.filter((curDate) => {
        return curDate.category === catItem;
      });
    }
    else {
      result = Courses;
    }
    console.log(result);


    setData(result);
  };

  const buttons = appState.objects.map(({ category, label, index }) => {
    return (
      <button
        type="button"
        id="button"
        className={toggleActiveStyles(index)}
        key={index}
        onClick={() => {
          toggleActiveStyles(index);
          toggleActive(index);
          filterResult(category);
        }}
      >
        {label}
      </button>
    );
  });

  return (
    <div className="all_courses" id="all_courses">
      <div className="all_courses_inner">
        <div className="all_courses_title">
          {t("allCoursesTextInAllCourses")}
        </div>

        <div className="all_courses_names">{buttons}</div>

        <div className="course_cards_container">
          {data.map((item) => {
            const { id } = item;
            return <CourseCard key={id} {...item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
